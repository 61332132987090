const homeCards = [
  {
    key: "loan_calculator",
    name: "Kredyt",
    description:
      "Oblicz ratę kredytu gotówkowego lub hipotecznego. Sprawdź strukturę raty oraz zobacz jaka będzie całkowita wysokość odsetek.",
    tags: [{ name: "Kalkulator", colorTheme: "yellow" }],
    path: "/calculator/loan",
  },
  {
    key: "loan_overpayment_calculator",
    name: "Nadpłata kredytu",
    description:
      "Sprawdź ile możesz zaoszczędzić nadpłacając kredyt gotówkowy lub hipoteczny.",
    tags: [{ name: "Kalkulator", colorTheme: "yellow" }],
    path: "/calculator/loan-overpayment",
  },
  {
    key: "loan_interest_rate_calculator",
    name: "Zmiana oprocentowania kredytu",
    description:
      "Sprawdź jak zmiana oprocentowania kredytu wpłynie na wysokość Twojej raty.",
    tags: [{ name: "Kalkulator", colorTheme: "yellow" }],
    path: "/calculator/cash-loan",
  },
  // {
  //   key: "mortgage_simulator",
  //   name: "Kredyt hipoteczny",
  //   description:
  //     "Przeprowadź symulację kredytu hipotecznego. Przetestuj różne scenariusze. Sprawdź ile zaoszczędzisz na nadpłacie kredytu.",
  //   tags: [
  //     { name: "Symulator", colorTheme: "cyan" },
  //     { name: "Kalkulator", colorTheme: "yellow" },
  //   ],
  //   path: "/simulator/mortgage",
  // },
  {
    key: "bond_calculator",
    name: "[WIP] Obligacje",
    description: "W przygotowaniu...",
    tags: [{ name: "Kalkulator", colorTheme: "yellow" }],
    path: "/calculator/bonds",
  },
  {
    key: "deposit_calculator",
    name: "Kalkulator lokaty",
    description: "Oblicz zysk z lokaty uwzględniając podatek Belki.",
    tags: [{ name: "Kalkulator", colorTheme: "yellow" }],
    path: "/calculator/deposit",
  }
];

const getFilteredCards = (searchPhrase) => {
  return homeCards.filter((card) => {
    if (
      !searchPhrase ||
      card.name.includes(searchPhrase) ||
      card.description.includes(searchPhrase)
    )
      return true;

    return false;
  });
};

export default homeCards;

export { getFilteredCards };
