import React from "react";

import {
  Text,
} from "@chakra-ui/react";

const DepositSimulator = () => {
    return (
        <Text fontSize="17px" fontWeight={700} marginBottom="5px">
            Symulator lokaty
          </Text>
    );
}

export default DepositSimulator;